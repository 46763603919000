var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Список распродаж")]),_c('data-table',{attrs:{"url":"discounts/sales","headers":_vm.headers,"item-key":"name","clickable-rows":""},on:{"click:row":_vm.showSale},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var sale = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'sales-id', params: { id: sale.name } }}},[_vm._v(" "+_vm._s(sale.name)+" ")])]}},{key:"item.isActive",fn:function(ref){
var sale = ref.item;
return [_vm._v(_vm._s(_vm._f("yesNo")(sale.isActive)))]}},{key:"item.beginsAt",fn:function(ref){
var sale = ref.item;
return [_vm._v(_vm._s(_vm._f("dateTime")(sale.beginsAt)))]}},{key:"item.endsAt",fn:function(ref){
var sale = ref.item;
return [_vm._v(_vm._s(_vm._f("dateTime")(sale.endsAt)))]}},{key:"item.updatedAt",fn:function(ref){
var sale = ref.item;
return [_vm._v(_vm._s(_vm._f("dateTime")(sale.updatedAt)))]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }